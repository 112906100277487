<template>
  <div id="evolucao">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="0"
          color="primary"
          min-width="40"
          max-width="40"
          min-height="40"
          max-height="40"
          v-bind="attrs"
          v-on="on"
          @click="abrirAvaliacao()"
        >
          <v-icon> mdi-eye-plus-outline </v-icon>
        </v-btn>
      </template>
      <span>Iniciar Avaliação</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="550" eager>
      <v-card> Painel da Avaliação </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data: () => ({
    dialog: false,
  }),
  components: {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    abrirAvaliacao() {
      this.dialog = true;
    },
  },
  async created() {},
};
</script>

<style></style>
