<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "SIS-CONFIG",
  components: {},

  data() {
    return {
      script: null,
    };
  },
  methods: {},
  mounted() { },
};
</script>

<style>
@import '../node_modules/typeface-roboto/index.css';

#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
