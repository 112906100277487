import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
const firebaseConfig = require("./config/firebase.json");

// Your web app's Firebase configuration
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const app = firebase.app();

//Initialize Firebase admin app
const adminApp = firebase.initializeApp(firebaseConfig, "adminApp");
//##########################################################################
// Firebase references
//##########################################################################
const auth = app.auth();
const db = app.firestore();
const functions = app.functions("southamerica-east1");
const stg = app.storage();

if (location.hostname === "localhost") {
	//db.useEmulator("localhost", 9091);
	functions.useEmulator("localhost", 5001);
}

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint, FieldValue } = firebase.firestore;

export { db, auth, functions, stg, FieldValue, TimeStamp, GeoPoint, firebase, adminApp };
