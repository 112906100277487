<template>
  <v-card color="transparent" flat tile>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <Menu></Menu>
    <v-card-title class="ml-n4 mb-n8"></v-card-title>
    <v-card-title class="mt-n10">
      <v-btn-toggle color="deep-purple" v-model="inicial" class="ml-n4 mr-2">
        <v-btn min-height="30" min-width="30" small text value="A">A</v-btn>
        <v-btn min-height="30" min-width="30" small text value="B">B</v-btn>
        <v-btn min-height="30" min-width="30" small text value="C">C</v-btn>
        <v-btn min-height="30" min-width="30" small text value="D">D</v-btn>
        <v-btn min-height="30" min-width="30" small text value="E">E</v-btn>
        <v-btn min-height="30" min-width="30" small text value="F">F</v-btn>
        <v-btn min-height="30" min-width="30" small text value="G">G</v-btn>
        <v-btn min-height="30" min-width="30" small text value="H">H</v-btn>
        <v-btn min-height="30" min-width="30" small text value="I">I</v-btn>
        <v-btn min-height="30" min-width="30" small text value="J">J</v-btn>
        <v-btn min-height="30" min-width="30" small text value="K">K</v-btn>
        <v-btn min-height="30" min-width="30" small text value="L">L</v-btn>
        <v-btn min-height="30" min-width="30" small text value="M">M</v-btn>
        <v-btn min-height="30" min-width="30" small text value="N">N</v-btn>
        <v-btn min-height="30" min-width="30" small text value="O">O</v-btn>
        <v-btn min-height="30" min-width="30" small text value="P">P</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Q">Q</v-btn>
        <v-btn min-height="30" min-width="30" small text value="R">R</v-btn>
        <v-btn min-height="30" min-width="30" small text value="S">S</v-btn>
        <v-btn min-height="30" min-width="30" small text value="T">T</v-btn>
        <v-btn min-height="30" min-width="30" small text value="U">U</v-btn>
        <v-btn min-height="30" min-width="30" small text value="V">V</v-btn>
        <v-btn min-height="30" min-width="30" small text value="W">W</v-btn>
        <v-btn min-height="30" min-width="30" small text value="X">X</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Y">Y</v-btn>
        <v-btn min-height="30" min-width="30" small text value="Z">Z</v-btn>
      </v-btn-toggle>
      <v-text-field
        color="purple"
        dense
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome ou Contato do Paciente"
        single-line
        hide-details
        clearable
        class="mr-n4 caption"
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="pacientes"
      :search="search"
      :items-per-page="14"
      :sort-by="['nomeCompleto']"
      :sort-desc="[false]"
      :calculate-widths="true"
      :loading="carregandoPacientes"
    >
      <template v-slot:[`item.idade`]="{ item }">
        <span class="caption">{{
          formatarDataNascimento(item.dataNascimento)
            | moment("from", "now", true)
        }}</span>
      </template>
      <template v-slot:[`item.dataNascimento`]="{ item }">
        <span class="caption">{{ item.dataNascimento }}</span>
      </template>

      <template v-slot:[`item.nomeCompleto`]="{ item }">
        <span class="caption">{{ item.nomeCompleto }}</span>
      </template>

      <template v-slot:[`item.sexo`]="{ item }">
        <span class="caption">{{ item.sexo }}</span>
      </template>
      <template v-slot:[`item.telefoneCelular`]="{ item }">
        <span class="caption">{{
          formatarTelefone(item.telefoneCelular)
        }}</span>
      </template>
      <template v-slot:[`item.cpf`]="{ item }">
        <span class="caption">{{ formatarCPF(item.cpf) }}</span>
      </template>
      <template v-slot:[`item.telefoneFixo`]="{ item }">
        <span class="caption">{{ formatarTelefone(item.telefoneFixo) }}</span>
      </template>
      <template v-slot:[`item.telefoneParaRecados`]="{ item }">
        <span class="caption">{{
          formatarTelefone(item.telefoneParaRecados)
        }}</span>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="painelPaciente(item)"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="primary"
              min-width="40"
              max-width="40"
              min-height="40"
              max-height="40"
            >
              <v-icon> mdi-clipboard-pulse </v-icon>
            </v-btn>
          </template>
          <span>Painel do Paciente</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editarPaciente(item)"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="primary"
              min-width="40"
              max-width="40"
              min-height="40"
              max-height="40"
            >
              <v-icon> mdi-account-edit </v-icon>
            </v-btn>
          </template>
          <span>Alterar Dados do Paciente</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="removerPaciente(item)"
              class="ml-n0 mx-1"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="error"
              min-width="40"
              max-width="40"
              min-height="40"
              max-height="40"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Excluir Paciente</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <span class="caption">{{ item.fullName }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import {
  formatToCPF,
  formatToPhone,
  formatToCPFOrCNPJ,
} from "brazilian-values";
import Menu from "./menu/Menu";

export default {
  data() {
    return {
      search: "",
      developmentMode: false,
      inicial: null,
      overlay: false,
      loading: false,
      headers: [
        { text: "CPF", value: "cpf" },
        { text: "Nome", value: "nomeCompleto" },
        { text: "Sexo", value: "sexo" },
        { text: "Idade", value: "idade" },
        { text: "Nascimento", value: "dataNascimento" },
        { text: "Celular", value: "telefoneCelular" },
        { text: "Telefone Fixo", value: "telefoneFixo" },
        { text: "Recado", value: "telefoneParaRecados" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
    };
  },
  components: {
    Menu,
  },
  computed: {
    ...mapGetters({
      pacientes: "pacientes",
      carregandoPacientes: "carregandoPacientes",
    }),
  },
  watch: {
    async inicial(letra) {
      if (letra) {
        await this.getPacientes();
      }
    },
  },
  methods: {
    async removerPaciente(paciente) {
      this.$swal
        .fire({
          title: "Remover Paciente",
          text:
            "Você realmente deseja remover o(a) paciente " +
            paciente.nomeCompleto +
            " da plataforma BalanceSteady?",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.$swal.showLoading();
            this.$swal.getCancelButton().setAttribute("disabled", "");
            let apagarPaciente = functions.httpsCallable(
              "balancesteady-paciente-remover-api"
            );
            await apagarPaciente({
              identificador: paciente.id,
            })
              .then((result) => {
                return;
              })
              .catch((error) => {
                this.$swal.getCancelButton().setAttribute("enabled", "");
                this.$swal.showValidationMessage(`Erro: ${error}`);
                console.error(error.message);
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                icon: "warning",
                title: "Paciente removido com sucesso! ",
                toast: false,
                showConfirmButton: true,
                timer: 1000,
                timerProgressBar: true,
              })
              .then((result) => {
                return;
              });
          }
        });
    },
    async painelPaciente(paciente) {
      await this.$store.dispatch("setPacienteId", {
        identificador: paciente.id,
      });
      this.$router.replace({ name: "PainelDoPaciente" });
    },
    async editarPaciente(paciente) {
      await this.$store.dispatch("setPacienteId", {
        identificador: paciente.id,
      });
      this.$router.replace({ name: "PacienteEditar" });
    },
    async getPacientes() {
      await this.$store.dispatch("unbindPacientes");
      if (this.inicial) {
        await this.$store.dispatch("bindPacientes", {
          inicial: this.inicial,
        });
      }
    },
    formatarDataNascimento(dataNascimento) {
      return this.$moment(dataNascimento, "DD/MM/YYYY");
    },
    getColor(gender) {
      if (gender === "Feminino") return "pink";
      else return "primary";
    },
    formatarCPF(item) {
      return formatToCPFOrCNPJ(item);
    },
    formatarTelefone(item) {
      if (item) {
        return formatToPhone(item);
      }
    },
    async remove(customer) {
      console.info(customer);
    },
  },
  async created() {},
};
</script>
<style></style>
