import Dashboard from "../../components/structural/Dashboard.vue";
import Drawer from "./drawer";


export default[
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
        children: [
            ...Drawer,
        ]
    },
];
