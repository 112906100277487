import { db } from "../../firebase";

export default {
  state: {
    empresa: null,
  },
  mutations: {
    SET_EMPRESA(state, value) {
      state.empresa = value;
    },
  },
  actions: {
    async fetchEmpresa(context, empresa) {
      let empresaRef = db.collection("empresas").doc(empresa);
      let empresaDoc = await empresaRef.get();
      let empresaData = empresaDoc.data();

      context.commit("SET_EMPRESA", empresaData);
    },
  },
  getters: {
    empresa(state) {
      return state.empresa;
    },
  },
};
