import Pacientes from "../../components/functional/paciente/Pacientes.vue";
import PacienteNovo from "../../components/functional/paciente/Novo.vue";
import PacienteEditar from "../../components/functional/paciente/Editar.vue";
import PainelDoPaciente from "../../components/functional/ehr/PainelDoPaciente.vue";

export default [
  {
    path: "pacientes",
    name: "Pacientes",
    component: Pacientes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "pacienteNovo",
    name: "PacienteNovo",
    component: PacienteNovo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "pacienteEditar",
    name: "PacienteEditar",
    component: PacienteEditar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "painelDoPaciente",
    name: "PainelDoPaciente",
    component: PainelDoPaciente,
    meta: {
      requiresAuth: true,
    },
  },
];
