<template>
  <v-navigation-drawer
    app
    :isOpen="mini"
    :expand-on-hover="true"
    :permanent="true"
    color="purple darken-3"
    :hide-overlay="true"
    dark
    class="elevation-10"
  >
    <v-list dense nav class="py-0 purple darken-3">
      <v-list-item two-line>
        <v-list-item-avatar class="profile rounded-lg" size="30">
          <v-icon v-if="empresa">mdi-domain</v-icon>
          <v-icon v-else>mdi-walk</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{
            profissional.nome + profissional.sobrenome
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ empresa == null ? "Fisioterapeuta" : empresa.nomeComercial }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-4"></v-divider>
      <template>
        <v-list-item link router to="Pacientes">
          <v-list-item-action>
            <v-icon left class="mr-2"> mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="caption font-weight-light">
              Pacientes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <v-list-item link @click="signOut">
        <v-list-item-action>
          <v-icon fixed-width>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { auth, db } from "../../firebase";

export default {
  name: "app-drawer",
  data: () => ({
    available: true,
  }),
  components: {
    script: null,
  },
  computed: {
    ...mapGetters({
      profissional: "profissional",
      empresa: "empresa",
      items: "items",
    }),
  },
  methods: {
    signOut() {
      this.$swal
        .fire({
          title: "Sair do Sistema",
          text: "Você realmente deseja sair do BalanceSteady.ai?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value) {
            auth.signOut().then(() => {
              this.$router.go({
                name: "Root",
              });
            });
          }
        });
    },
  },
};
</script>

<style></style>
