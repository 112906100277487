import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterBackButton from "vue-router-back-button";

import { auth } from "../firebase";

import Login from "./modules/login";
import Dashboard from "./modules/dashboard";

Vue.use(VueRouter);

const routes = [...Login, ...Dashboard];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueRouterBackButton, { router });

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
