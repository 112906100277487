<template>
  <v-app id="app">
    <v-main class="mb-6 pa-0 mt-n15">
      <v-row class="d-flex flex-row transparent">
        <v-col class="d-flex flex-row justify-center transparent">
          <v-card class="d-flex flex-column justify-center" width="900" flat>
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                :rotate="360"
                :size="100"
                :width="8"
                color="white"
              >
              </v-progress-circular>
            </v-overlay>
            <v-card
              class="pa-10"
              color=" mb-n10 mt-n8 ml-15"
              :min-height="screenHeight"
              flat
              tile
              elevation="24"
            >
              <v-card-title class="d-flex justify-center align-self-center">
                <v-img
                  src="~@/assets/logo/logo.png"
                  max-height="400"
                  max-width="400"
                ></v-img>
              </v-card-title>
              <v-card-title
                class="titleCard mt-n15 d-flex justify-center font-weight-thin grey--text text--darken-1"
              >
                Sistema Inteligente de Fisioterapia Neurofuncional
              </v-card-title>
              <v-card-title
                class="titleSystem d-flex justify-center grey--text text--darken-0"
              >
                <font-awesome-icon
                  :icon="['fat', 'brain-circuit']"
                  fixed-width
                  style="font-size: 45px"
                />
              </v-card-title>
              <v-card-title
                class="titleSystem mt-n15 d-flex justify-center font-weight-thin grey--text text--darken-1"
              >
                <p
                  class="titleBuild text-center grey--text text--lighten-1 caption"
                >
                  v
                  {{
                    sisVersion.version.major +
                    "." +
                    sisVersion.version.minor +
                    "." +
                    sisVersion.version.patch
                  }}
                  - Last build: {{ sisVersion.build.date }}
                </p>
              </v-card-title>
              <v-stepper v-model="stp" class="elevation-0">
                <v-stepper-header>
                  <v-stepper-step :complete="stp > 1" step="1">
                    E-mail
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2" :complete="stp > 2">
                    Senha
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3"> Empresa </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-text-field
                      class="mt-1"
                      v-model="id"
                      :rules="[rules.emailObrigatorio, rules.email]"
                      label="E-mail"
                      placeholder="Digite o seu email cadastrado na plataforma"
                      hint="Digite seu email"
                      outlined
                      clearable
                      @keydown.enter.prevent="verificarUsuario()"
                    ></v-text-field>
                    <v-btn
                      class="mt-2"
                      :disabled="id == null"
                      color="primary"
                      @click="verificarUsuario()"
                    >
                      <v-icon> mdi-login-variant </v-icon>
                      Verificar
                    </v-btn>
                    <v-btn
                      text
                      :disabled="id == null"
                      class="ml-2 mt-2"
                      color="primary"
                      @click="reinicializarSenha()"
                    >
                      Esqueci Minha Senha
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-text-field
                      class="mt-1"
                      v-model="pin"
                      :rules="[rules.senhaObrigatoria]"
                      label="Senha"
                      placeholder="Digite a sua senha cadastrada na plataforma"
                      hint="Digite sua senha"
                      outlined
                      clearable
                      type="password"
                      :disabled="loading"
                      @keydown.enter.prevent="login()"
                    ></v-text-field>
                    <v-btn class="mt-2" color="primary" @click="login()">
                      <v-icon> mdi-login-variant </v-icon>
                      Entrar
                    </v-btn>
                    <v-btn class="ml-2 mt-2" text @click="cancelar()">
                      Cancelar
                    </v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-select
                      v-model="empresa"
                      outlined
                      clearable
                      :rules="[rules.empresaObrigatoria]"
                      chips
                      label="Escolha a empresa em que irá atender"
                      placeholder="Escolha a empresa em que irá atender pela plataforma"
                      hint="Escolha a empresa"
                      :items="empresas"
                      variant="outlined"
                      class="mt-1"
                      @keydown.enter.prevent="configurar()"
                    ></v-select>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      @click="escolherEmpresa()"
                    >
                      <v-icon> mdi-login-variant </v-icon>
                      Escolher
                    </v-btn>
                    <v-btn class="ml-2 mt-2" text @click="cancelar()">
                      Cancelar
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { auth, functions } from "../firebase";

export default {
  data() {
    return {
      overlay: false,
      label: "Identificador do Profissional",
      ui: null,
      id: null,
      stp: 1,
      pin: null,
      empresas: null,
      empresa: null,
      errorMessage: null,
      sisVersion: null,
      screenHeight: 0,
      contaPessoal: {
        value: "autônomo",
        text: "Profissional Autônomo",
      },
      rules: {
        emailObrigatorio: (value) =>
          !!value || "O seu e-mail é obrigatório para acessar o sistema.",
        senhaObrigatoria: (value) =>
          !!value || "A sua senha é obrigatória para acessar o sistema.",
        empresaObrigatoria: (value) =>
          !!value || "É obrigatório escolher a empresa para acessar o sistema.",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    errorMessage(newValue, oldValue) {
      if (newValue) {
        this.$swal.fire({
          icon: "warning",
          title: "Atenção",
          toast: false,
          showConfirmButton: true,
          timer: 8000,
          timerProgressBar: true,
          text: newValue,
        });
      }
    },
  },
  methods: {
    cancelar() {
      this.loading = false;
      this.stp = 1;
      this.pin = null;
      this.id = null;
      this.errorMessage = null;
      this.overlay = false;
    },
    verificarUsuario() {
      this.stp = 2;
    },
    reinicializarSenha() {
      if (this.id) {
        this.overlay = true;
        this.$swal
          .fire({
            title: "Redefinir Senha?",
            text: "Você deseja redefinir sua senha?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
          .then(async (result) => {
            if (result.value) {
              auth
                .sendPasswordResetEmail(this.id)
                .then(() => {
                  this.$swal
                    .fire({
                      icon: "success",
                      title: "E-mail enviado com sucesso!",
                      text: "Foi enviado para seu email o processo de definição de uma nova senha para acessar a plataforma abalanceSteady.ai.",
                      toast: false,
                      showConfirmButton: true,
                      timer: 3000,
                      timerProgressBar: true,
                    })
                    .then((result) => {
                      this.overlay = false;
                      this.$router.replace({ name: "Login" });
                    });
                })
                .catch((error) => {
                  if (!this.id) {
                    this.errorMessage =
                      "É obrigatório digitar o e-mail cadastrado no sistema para reinicarlizar sua senha!";
                  } else {
                    this.errorMessage = error.code + "-" + error.message;
                  }
                  this.noError = false;
                  this.overlay = false;
                });
            }
          });
      }
    },
    async escolherEmpresa() {
      if (this.empresa != "autônomo") {
        await this.$store.dispatch("fetchEmpresa", this.empresa);
      }
      this.$router.replace({
        name: "Pacientes",
      });
    },
    login() {
      this.overlay = true;
      auth
        .signInWithEmailAndPassword(this.id, this.pin)
        .then((userCredential) => {
          userCredential.user
            .getIdTokenResult()
            .then(async (idTokenResult) => {
              if (idTokenResult.claims.profissional) {
                let validaProfissional = functions.httpsCallable(
                  "balancesteady-autenticacao-valida-profissional-api"
                );
                validaProfissional({ profissional: idTokenResult.claims.cpf })
                  .then(async (result) => {
                    await this.$store.dispatch(
                      "fetchProfissional",
                      idTokenResult.claims.cpf
                    );

                    if (result.data.vinculadoEmpresa) {
                      this.overlay = false;
                      this.empresas = result.data.empresas;
                      this.stp = 3;
                    } else {
                      this.overlay = false;
                      this.$router.replace({
                        name: "Pacientes",
                      });
                    }
                  })
                  .catch((error) => {
                    this.overlay = false;
                    this.errorMessage =
                      "Você ainda não está ativo na plataforma BalanceSteady. Por favor, aguarde a validação dos seus dados profissionais.";
                    this.noError = false;
                    this.stp = 1;
                  });
              } else {
                this.overlay = false;
                this.errorMessage =
                  "Profissional não autorizado na plataforma.";
                this.noError = false;
                this.loading = false;
                this.pin = null;
              }
            })
            .catch((error) => {
              this.errorMessage = "Profissional não autorizado na plataforma.";
              this.noError = false;
              this.loading = false;
              this.pin = null;
              this.overlay = false;
            });
        })
        .catch((error) => {
          this.loading = false;
          this.pin = null;
          this.errorMessage =
            "Usuário ou senha inválidos, por favor, tentar novamente.";
          this.noError = false;
          this.overlay = false;
        });
    },
  },
  created() {
    this.sisVersion = this.$sisVersion;
    this.screenHeight = screen.height;
  },
};
</script>

<style scoped>
.titleCard {
  font-size: 25px !important;
  line-height: 1.6;
}

.titleSystem {
  font-size: 16px !important;
  line-height: 1.4;
}

.titleBuild {
  font-size: 10px !important;
  line-height: 1.6;
}

.v-stepper__header {
  box-shadow: none;
}
</style>
