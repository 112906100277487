<template>
  <v-card flat class="pa-2">
    <v-card-title
      class="overline subtitle-2 grey--text text--darken-3 mt-n3 ml-n1"
    >
      <v-icon class="mr-1"> mdi-heart-pulse </v-icon> Exame Físico-Clínico:
    </v-card-title>
    <v-stepper non-linear class="transparent" v-model="passos" vertical flat>
      <v-stepper-step step="1" editable>
        <span class="overline grey--text text--darken-1"
          ><v-icon class="grey--text text--darken-2" left> mdi-pulse </v-icon>
          Sinais Vitais
        </span></v-stepper-step
      >
      <v-stepper-content step="1">
        <v-card class="mb-12 transparent d-flex flex-column" max-width="20%" flat>
          <span class="mt-1 mr-2 overline grey--text text--darken-1"> Sistólica: </span>
          <v-text-field-integer
            v-model="value"
            v-bind:label="Sistólica"
            v-bind:properties="{
              suffix: 'mmHg',
              readonly: false,
              disabled: false,
              outlined: true,
              clearable: true,
              placeholder: '',
            }"
            v-bind:options="{
              inputMask: '###',
              outputMask: '###',
              empty: null,
              applyAfter: false,
            }"
            v-bind:focus="focus"
            v-on:focus="focus = false"
          />
          <span class="mt-1 mr-2 overline grey--text text--darken-1"> Distólica: </span>
          <v-text-field-integer
            v-model="value"
            v-bind:label="Diastólica"
            v-bind:properties="{
              suffix: 'mmHg',
              readonly: false,
              disabled: false,
              outlined: true,
              clearable: true,
              placeholder: '',
            }"
            v-bind:options="{
              inputMask: '###',
              outputMask: '###',
              empty: null,
              applyAfter: false,
            }"
            v-bind:focus="focus"
            v-on:focus="focus = false"
          />
        </v-card>
        <v-btn color="primary" @click="passos = 2"> Próximo </v-btn>
      </v-stepper-content>

      <v-stepper-step step="2" editable>
        <span class="overline grey--text text--darken-1"
          ><v-icon class="grey--text text--darken-2" left>
            mdi-human-male-height </v-icon
          >Dados Antropométricos</span
        ></v-stepper-step
      >
      <v-stepper-content step="2">
        <v-card class="mb-12 transparent" flat></v-card>
        <v-btn color="primary" @click="gravar">
          Adicionar Dados Clínicos
        </v-btn>
        <v-btn class="ml-4" color="warning" @click="passos = 1"> Voltar </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      passos: 1,
    };
  },
};
</script>
