import Vue from "vue";
import Vuex from "vuex";
import {vuexfireMutations, firestoreOptions} from "vuexfire";

import profissional from "./modules/profissional";
import pacientes from "./modules/pacientes";
import empresa from "./modules/empresa";
import config from "./modules/config";

firestoreOptions.wait = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        profissional,
        pacientes,
        config,
        empresa,
    },

    mutations: {
        ...vuexfireMutations
    }
});

export default store;
