import { db } from "../../firebase";

export default {
  state: {
    config: null,
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.config = data;
    },
  },
  actions: {
    fetchConfig(context) {
      db.collection("admin")
        .doc("sis")
        .get()
        .then((configDoc) => {
          context.commit("SET_CONFIG", configDoc.data());
        });
    },
  },
  getters: {
    config(state) {
      return state.config;
    },
  },
};
