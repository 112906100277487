import Vue from "vue";
import App from "./App.vue";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import VueMeta from "vue-meta";
Vue.use(VueMeta, { // optional pluginOptions
    refreshOnceOnNavigation: true
});

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";

import {TiptapVuetifyPlugin} from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import {auth, db} from "./firebase";

import "./scss/main.scss";

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(VueSweetalert2);
Vue.use(TiptapVuetifyPlugin, {vuetify, iconsGroup: "mdi"});

const moment = require("moment");

require("moment/locale/pt");
Vue.use(require("vue-moment"), {moment});

import VuetifyMask from "vuetify-mask";
Vue.use(VuetifyMask);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import CountdownPlus from 'vue-countdown-plus'
Vue.component(CountdownPlus.name, CountdownPlus);

Vue.component("apexchart", VueApexCharts);

Vue.prototype.$sisVersion = require("./version/appversion.json");

var app;
auth.onAuthStateChanged(async (user) => {
    if (! app) {
        if (user) {
            await auth.signOut();
        }
        app = new Vue({
            router,
            store,
            vuetify,
            metaInfo: () => (
                {title: "Balance Steady AI - Sistema de Inteligência em Fisioterapia Neurofuncional"}
            ),
            render: (h) => h(App)
        }).$mount("#app");
    }

    if (user) {
        user.getIdTokenResult().then(async (idTokenResult) => {
            if (!idTokenResult.claims.profissional) {
                auth.signOut().then(() => {
                    router.go();
                });
            }
        });
        if (!user.getIdToken(false)) {
            auth.signOut().then(() => {
                router.go();
            });
        }
    }
});
