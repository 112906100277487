<template>
  <v-card flat>
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <v-icon left> mdi-account</v-icon>Pacientes
      </v-card-title>
      <v-spacer></v-spacer>
      <!--Buttons Actions -->
      <div class="mr-n5">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-3"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="primary"
              @click="novoPaciente()"
              text
            >
              <v-icon left dark class="mr-2">mdi-account-plus</v-icon>
              Novo
            </v-btn>
          </template>
          <span>Adicionar Novo Paciente</span>
        </v-tooltip>
      </div>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    novoPaciente() {
      this.$router.push("PacienteNovo");
    },
  },
  mounted() {},
};
</script>
