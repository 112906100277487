import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    pacientes: [],
    pacienteId: null,
    carregandoPacientes: false,
  },
  mutations: {
    SET_CARREGANDO_PACIENTES(state, data) {
      state.carregandoPacientes = data;
    },
    SET_PACIENTE_ID(state, data) {
      state.pacienteId = data;
    },
  },
  actions: {
    unbindPacientes: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("pacientes", true);
    }),
    bindPacientes: firestoreAction(async (context, data) => {
      context.commit("SET_CARREGANDO_PACIENTES", true);
      let inicialNome = data.inicial;

      let ref = db.collection("pacientes");
      if (inicialNome) {
        ref = ref.where("inicial", "==", inicialNome);
      }

      if (context.getters.empresa) {
        ref = ref.where("empresa", "==", context.getters.empresa.cnpj);
      } else {
        ref = ref.where("profissional", "==", context.getters.profissional.cpf);
      }
      
      await context.bindFirestoreRef("pacientes", ref, {
        wait: true,
        reset: true,
      });

      context.commit("SET_CARREGANDO_PACIENTES", false);
    }),
    async setPacienteId(context, data) {
      context.commit("SET_PACIENTE_ID", data.identificador);
    },
  },
  getters: {
    pacientes(state) {
      return state.pacientes;
    },
    pacienteId(state) {
      return state.pacienteId;
    },
    carregandoPacientes(state) {
      return state.carregandoPacientes;
    },
  },
};
