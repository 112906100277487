import {db, functions, firebase} from "../../firebase";
import {firestoreAction} from "vuexfire";

export default {
    state: {
        profissional: {},
    },
    mutations: {
        SET_PROFISSIONAL(state, value) {
            state.profissional = value;
        }
    },
    actions: {
        async fetchProfissional(context, profissional) {
            context.dispatch("fetchConfig");

            let profissionalRef = db.collection("profissionais").doc(profissional);
            let profissionalDoc = await profissionalRef.get();
            let profissionalData = profissionalDoc.data();

            context.commit("SET_PROFISSIONAL", profissionalData);
        }
    },
    getters: {
        profissional(state) {
            return state.profissional;
        }
    }
};
