<template>
  <div id="evolucao">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-1 mr-n2"
          text
          elevation="0"
          color="primary"
          min-width="30"
          max-width="30"
          min-height="30"
          max-height="30"
          v-bind="attrs"
          v-on="on"
          @click="abrirCondutas()"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Adicionar Condutas</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="550" eager>
      <v-card> Painel de Condutas </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data: () => ({
    dialog: false,
  }),
  components: {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    abrirCondutas() {
      this.dialog = true;
    },
  },
  async created() {},
};
</script>

<style></style>
