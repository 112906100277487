<template>
  <v-card color="transparent d-flex flex-column" flat class="pa-1">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <v-card class="elevation-20 pa-2 white d-flex flex-row mb-3" flat>
      <v-spacer />
      <ExameFisico></ExameFisico>
      <Evolucao></Evolucao>
      <Avaliacao></Avaliacao>

      <v-divider class="mx-4" vertical></v-divider>

      <router-link
        v-if="$routerHistory.hasPrevious()"
        :to="{ path: $routerHistory.previous().path }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              color="secondary"
              min-width="40"
              max-width="40"
              min-height="40"
              max-height="40"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-history </v-icon>
            </v-btn>
          </template>
          <span>Voltar</span>
        </v-tooltip>
      </router-link>
    </v-card>
    <!--Painel Paciente-->
    <v-card class="elevation-0 d-flex flex-column transparent" flat>
      <v-card-text class="overline grey--text text--darken-3">
        <v-row class="mt-n6">
          <v-col>
            <span class="mr-2 grey--text text--darken-0">
              <v-icon class="mr-1 mb-1 ml-n1"> mdi-account-circle </v-icon
              >Painel do Paciente:
            </span>
            {{ paciente.nomeCompleto }}
            <span class="ml-2 mr-2 grey--text text--darken-0">Sexo:</span>
            {{ paciente.sexo }}
            <span class="ml-2 mr-2 grey--text text--darken-0"
              >Data Nascimento:</span
            >
            {{ paciente.dataNascimento }}
            <span class="ml-2 mr-2 grey--text text--darken-0">Idade:</span>
            {{
              formatarDataNascimento(paciente.dataNascimento)
                | moment("from", "now", true)
            }}
            <span class="ml-2 mr-2 grey--text text--darken-0">Profissão:</span>
            {{ paciente.ocupacional.profissao }}
            <v-dialog v-model="profissaoDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  color="secondary"
                  min-width="30"
                  max-width="30"
                  min-height="30"
                  max-height="30"
                >
                  <v-icon>mdi-briefcase-outline</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="subtitle-2 grey lighten-3">
                  Principais Atividades no Trabalho
                </v-card-title>

                <v-card-text class="mt-2">
                  {{ paciente.ocupacional.atividades }}
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="mt-n4 grey--text text--darken-0">
          <v-col>
            <v-icon class="mr-1 mb-1 ml-n1"> mdi-hospital-box-outline </v-icon
            >Diagnóstico Funcional:
            <v-chip
              class="overline mr-4"
              small
              label
              outlined
              color="success text--darken-2"
              >Hipofunção do canal lateral direito</v-chip
            >
            Código CIF:
            <v-chip
              class="overline mr-4"
              small
              label
              outlined
              color="success text--darken-2"
              >S8453</v-chip
            >
          </v-col>
        </v-row>
        <v-row class="mt-n4 grey--text text--darken-0">
          <v-col>
            <v-icon class="mr-1 mb-1 ml-n1"> mdi-hospital-box-outline </v-icon
            >Diagnóstico Clínico:
            <v-chip class="mr-4" label outlined color="secondary" small
              >VPPB</v-chip
            >Código CID:
            <v-chip class="mr-4" small label outlined color="secondary"
              >H81.1</v-chip
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--Painel Objetivos e Condutas-->
    <v-row>
      <v-col>
        <v-card
          min-height="150"
          class="elevation-20 d-flex flex-column"
          outlined
        >
          <v-card-title
            class="overline subtitle-2 grey--text text--darken-3 mt-n3 ml-n1"
          >
            <v-icon class="mr-1"> mdi-bullseye </v-icon> Objetivos:
            <v-spacer />
            <Objetivos></Objetivos>
          </v-card-title>
          <v-card-text class="mt-n3 ml-n1 mb-n3">
            <v-chip close class="mr-2 mb-2 overline" label color="primary" dark>
              Melhora do Equilíbrio Corporal
            </v-chip>
            <v-chip close class="mr-2 mb-2 overline" label color="primary" dark>
              Ganho de força
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          min-height="150"
          class="elevation-20 d-flex flex-column"
          outlined
        >
          <v-card-title
            class="overline subtitle-2 grey--text text--darken-3 mt-n3 ml-n1"
          >
            <v-icon class="mr-1"> mdi-clipboard-list-outline </v-icon> Condutas
            em andamento:
            <v-spacer />
            <Condutas></Condutas>
          </v-card-title>
          <v-card-text class="mt-n3 ml-n1 mb-n3">
            <v-chip close class="mr-2 mb-2 overline" label color="purple" dark>
              <v-icon left small> mdi-medical-bag </v-icon>Melhora do Equilíbrio
              Corporal
            </v-chip>
            <v-chip close class="mr-2 mb-2 overline" label color="purple" dark>
              <v-icon left small> mdi-medical-bag </v-icon>Exercícios de força
            </v-chip>
            <v-chip close class="mr-2 mb-2 overline" label color="success" dark>
              <v-icon left small> mdi-home </v-icon>Fisioterapia Vestibular em
              Casa
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-4 d-flex flex-column elevation-0 pa-1 transparent">
      <v-card-title
        class="overline subtitle-2 grey--text text--darken-3 mt-n3 ml-n1"
        ><v-icon class="mr-1"> mdi-clock-check-outline </v-icon
        >Atendimentos:</v-card-title
      >
      <v-data-table
        dense
        :headers="headers"
        :items="atendimentos"
        item-key="data"
        class="elevation-0"
        :calculate-widths="true"
      >
        <template v-slot:[`item.etiqueta`]="{ item }">
          <v-chip class="overline" outlined label color="primary" small>
            {{ item.etiqueta }}</v-chip
          >
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="visualizar(item)"
                class="ml-n0 ma-1"
                v-bind="attrs"
                v-on="on"
                text
                elevation="0"
                color="primary"
                min-width="30"
                max-width="30"
                min-height="30"
                max-height="30"
              >
                <v-icon> mdi-note-outline </v-icon>
              </v-btn>
            </template>
            <span>Visualizar Avaliações</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import {
  formatToCPF,
  formatToPhone,
  formatToCPFOrCNPJ,
} from "brazilian-values";
import Evolucao from "../ehr/components/anamnese/Evolucao.vue";
import Avaliacao from "../ehr/components/anamnese/Avaliacao.vue";
import ExameFisico from "../ehr/components/anamnese/Fisico.vue";
import Objetivos from "../ehr/components/objetivos/Objetivos.vue";
import Condutas from "../ehr/components/condutas/Condutas.vue";

export default {
  data() {
    return {
      overlay: false,
      paciente: null,
      profissaoDialog: false,
      atendimentos: [
        {
          data: "18/09/2023",
          tipo: "Evolução",
          avaliacao: "-",
          pontuacao: "-",
          etiqueta: "-",
          fisioterapeuta: "Flávia Doná",
          empresa: "Neuromovimento",
        },
        {
          data: "17/09/2023",
          tipo: "Avaliação",
          avaliacao: "Padrão",
          pontuacao: "50",
          etiqueta: "RISCO DE QUEDAS",
          fisioterapeuta: "Flávia Doná",
          empresa: "Neuromovimento",
        },
        {
          data: "17/09/2023",
          tipo: "Avaliação",
          avaliacao: "DGI",
          pontuacao: "50",
          etiqueta: "BAIXA QUALIDADE DE VIDA",
          fisioterapeuta: "Denise Alves",
          empresa: "Neuromovimento",
        },
      ],
      headers: [
        { text: "Data", value: "data" },
        {
          text: "Tipo",
          value: "tipo",
        },
        { text: "Fisioterapeuta", value: "fisioterapeuta" },
        { text: "Avaliação", value: "avaliacao" },
        { text: "Pontuação", value: "pontuacao" },
        { text: "Marcação", value: "etiqueta" },
        { text: "Empresa", value: "empresa" },
        { text: "Ações", value: "acoes" },
      ],
    };
  },
  components: { Evolucao, Avaliacao, ExameFisico, Objetivos, Condutas },
  computed: {
    ...mapGetters({
      pacienteId: "pacienteId",
    }),
  },
  watch: {},
  methods: {
    formatarDataNascimento(dataNascimento) {
      return this.$moment(dataNascimento, "DD/MM/YYYY");
    },
  },
  async created() {
    this.overlay = true;
    let obterPaciente = functions.httpsCallable(
      "balancesteady-paciente-obter-api"
    );
    await obterPaciente({
      identificador: this.pacienteId,
    })
      .then((result) => {
        this.paciente = result.data.paciente;
        this.overlay = false;
      })
      .catch((error) => {
        this.overlay = false;
        this.$swal.getCancelButton().setAttribute("enabled", "");
        this.$swal.showValidationMessage(`Erro: ${error}`);
        console.error(error.message);
      });
  },
};
</script>
<style></style>
