<template>
  <v-card flat class="mb-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        :rotate="360"
        :size="100"
        :width="8"
        color="white"
      >
      </v-progress-circular>
    </v-overlay>
    <v-toolbar dense rounded outlined elevation="0">
      <v-card-title class="ml-n6 grey--text text--darken-2">
        <v-icon left> mdi-account-plus-outline </v-icon>
        Atualizar Paciente
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        dark
        text
        color="primary"
        elevation="0"
        @click="salvarPaciente()"
      >
        <v-icon dark>mdi-refresh</v-icon> Atualizar
      </v-btn>
      <v-btn
        class="mr-n2"
        color="error"
        text
        elevation="0"
        @click="$router.replace({ name: 'Pacientes' })"
        >CANCELAR
      </v-btn>
    </v-toolbar>

    <v-card class="mx-auto mt-4" flat max-width="99%">
      <v-tabs
        color="purple"
        v-model="tab"
        icons-and-text
        class="rounded"
        height="60"
      >
        <v-tabs-slider color="orange"></v-tabs-slider>
        <v-tab>Geral</v-tab>
        <v-tab>Documentos</v-tab>
        <v-tab>Endereço</v-tab>
        <v-tab>Ocupacional</v-tab>
        <v-tab>Plano de Saúde</v-tab>
      </v-tabs>
      <v-form lazy-validation v-model="valid" ref="form">
        <v-tabs-items class="transparent" v-model="tab">
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Nome completo"
                  placeholder="Nome completo"
                  outlined
                  required
                  :rules="[
                    (v) =>
                      !!v ||
                      'O nome do paciente é de preenchimento obrigatório',
                  ]"
                  v-model="nomeCompleto"
                ></v-text-field>

                <v-text-field-simplemask
                  v-model="telefoneFixo"
                  label="Telefone Fixo"
                  v-bind:properties="{
                    rules: [],
                    required: false,
                    prefix: '',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '(##) ####-####',
                    outputMask: '(##) ####-####',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                  }"
                />

                <v-text-field-simplemask
                  v-model="telefoneCelular"
                  label="Celular"
                  v-bind:properties="{
                    rules: [],
                    required: false,
                    prefix: '',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '(##) #####-####',
                    outputMask: '(##) #####-####',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                  }"
                />
                <v-text-field-simplemask
                  v-model="telefoneParaRecados"
                  label="Telefone para Recados (Celular)"
                  v-bind:properties="{
                    rules: [],
                    required: false,
                    prefix: '',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '(##) #####-####',
                    outputMask: '(##) #####-####',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                  }"
                />

                <v-select
                  :items="sexos"
                  label="Sexo"
                  clearable
                  outlined
                  :rules="[
                    (v) =>
                      !!v ||
                      'O sexo do paciente é de preenchimento obrigatório',
                  ]"
                  required
                  v-model="sexo"
                ></v-select>
                <v-text-field-simplemask
                  v-model="dataNascimento"
                  required
                  label="Data nascimento"
                  v-bind:properties="{
                    required: true,
                    rules: [
                      (v) =>
                        !!v ||
                        'A data de nascimento do paciente é de preenchimento obrigatório',
                    ],
                    prefix: '',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '##/##/####',
                    outputMask: '##/##/####',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                  }"
                />
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field-simplemask
                  v-model="cpf"
                  label="CPF"
                  required
                  v-bind:properties="{
                    rules: [
                      (v) =>
                        !!v ||
                        'O CPF do paciente é de preenchimento obrigatório',
                    ],
                    required: true,
                    prefix: '',
                    suffix: '',
                    readonly: true,
                    disabled: true,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '###.###.###-##',
                    outputMask: '###########',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                  }"
                />
                <v-text-field
                  label="RG"
                  placeholder="RG"
                  outlined
                  v-model="rg"
                ></v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      label="Rua, Avenida, ..."
                      placeholder=""
                      outlined
                      v-model="paciente.endereco.endereco"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field-integer
                      v-model="numeroDoEndereco"
                      label="Número"
                      v-bind:properties="{
                        readonly: false,
                        disabled: false,
                        outlined: true,
                        clearable: true,
                        placeholder: '',
                      }"
                      v-bind:options="{
                        inputMask: '#####',
                        outputMask: '#####',
                        empty: null,
                        applyAfter: false,
                      }"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col cols="4">
                    <v-text-field
                      label="Complemento"
                      placeholder=""
                      outlined
                      v-model="paciente.endereco.complemento"
                    />
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="Bairro"
                      placeholder=""
                      outlined
                      v-model="paciente.endereco.bairro"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-n6">
                  <v-col cols="4">
                    <v-select
                      :items="estados"
                      item-text="name"
                      item-value="code"
                      label="UF"
                      clearable
                      outlined
                      v-model="paciente.endereco.estado"
                    ></v-select>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="Cidade"
                      placeholder=""
                      outlined
                      v-model="paciente.endereco.cidade"
                    />
                  </v-col>
                </v-row>
                <v-text-field-cep
                  v-model="cep"
                  label="CEP"
                  v-bind:properties="{
                    disabled: false,
                    outlined: true,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    outputMask: '########',
                    empty: null,
                    applyAfter: true,
                  }"
                />
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Profissão"
                  placeholder=""
                  outlined
                  v-model="paciente.ocupacional.profissao"
                >
                </v-text-field>
                <v-textarea
                  label="Atividades Profissionais"
                  placeholder=""
                  outlined
                  v-model="paciente.ocupacional.atividades"
                ></v-textarea>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="rounded pa-2 mt-2">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Plano de Saúde"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.nome"
                >
                </v-text-field>
                <v-text-field
                  label="Número do Beneficiário"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.codigo"
                ></v-text-field>
                <v-text-field
                  label="Segmentação"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.segmentacao"
                ></v-text-field>
                <v-text-field
                  label="Acomodação"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.acomodacao"
                ></v-text-field>
                <v-text-field
                  label="Rede de Atendimento"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.redeDeAtendimento"
                ></v-text-field>
                <v-text-field
                  label="Inclusão"
                  placeholder=""
                  outlined
                  v-model="paciente.planoDeSaude.inclusao"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
const estados = require("../../../general/estados.json");

export default {
  data() {
    return {
      overlay: false,
      tab: null,
      paciente: {},
      nomeCompleto: null,
      cpf: null,
      rg: null,
      telefoneFixo: null,
      telefoneCelular: null,
      telefoneParaRecados: null,
      userId: null,
      sexo: null,
      dataNascimento: null,
      estados: estados,
      numeroDoEndereco: null,
      cep: null,
      sexos: [
        { text: "Masculino", value: "Masculino" },
        { text: "Feminino", value: "Feminino" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      pacienteId: "pacienteId",
    }),
  },
  watch: {
    nomeCompleto(newValue, oldValue) {
      this.paciente.nomeCompleto = newValue;
    },
    sexo(newValue, oldValue) {
      this.paciente.sexo = newValue;
    },
    telefoneFixo(newValue, oldValue) {
      this.paciente.telefoneFixo = newValue;
    },
    telefoneCelular(newValue, oldValue) {
      this.paciente.telefoneCelular = newValue;
    },
    telefoneParaRecados(newValue, oldValue) {
      this.paciente.telefoneParaRecados = newValue;
    },
    cpf(newValue, oldValue) {
      this.paciente.cpf = newValue;
    },
    rg(newValue, oldValue) {
      this.paciente.rg = newValue;
    },
    dataNascimento(newValue, oldValue) {
      this.paciente.dataNascimento = newValue;
    },
    numeroDoEndereco(newValue, oldValue) {
      this.paciente.endereco.numero = newValue;
    },
    cep(newValue, oldValue) {
      this.paciente.endereco.cep = newValue;
    },
  },

  methods: {
    async salvarPaciente() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$swal
          .fire({
            title: "Atualizar Paciente",
            text:
              "Você realmente deseja atualizar os dados  do(a) paciente " +
              this.paciente.nomeCompleto +
              "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              this.$swal.showLoading();
              this.$swal.getCancelButton().setAttribute("disabled", "");
              let atualizarPaciente = functions.httpsCallable(
                "balancesteady-paciente-alterar-api"
              );
              await atualizarPaciente({
                paciente: this.paciente,
                identificador: this.pacienteId,
              })
                .then((result) => {
                  return;
                })
                .catch((error) => {
                  this.$swal.getCancelButton().setAttribute("enabled", "");
                  this.$swal.showValidationMessage(`Erro: ${error}`);
                  console.error(error.message);
                });
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal
                .fire({
                  icon: "success",
                  title: "Paciente atualiado com sucesso! ",
                  toast: false,
                  showConfirmButton: true,
                  timer: 1000,
                  timerProgressBar: true,
                })
                .then((result) => {
                  this.$router.replace({ name: "Pacientes" });
                });
            }
          });
      }
    },
  },
  async created() {
    this.overlay = true;
    let obterPaciente = functions.httpsCallable(
      "balancesteady-paciente-obter-api"
    );
    await obterPaciente({
      identificador: this.pacienteId,
    })
      .then((result) => {
        this.paciente = result.data.paciente;
        this.nomeCompleto = this.paciente.nomeCompleto;
        this.sexo = this.paciente.sexo;
        this.telefoneFixo =
          this.paciente.telefoneFixo == null
            ? null
            : this.paciente.telefoneFixo;
        this.telefoneCelular =
          this.paciente.telefoneCelular == null
            ? null
            : this.paciente.telefoneCelular;
        this.telefoneParaRecados =
          this.paciente.telefoneParaRecados == null
            ? null
            : this.paciente.telefoneParaRecados;

        this.cpf = this.paciente.cpf;
        this.rg = this.paciente.rg;
        this.dataNascimento = this.paciente.dataNascimento;
        this.numeroDoEndereco =
          this.paciente.endereco.numero == null
            ? null
            : this.paciente.endereco.numero;
        this.cep =
          this.paciente.endereco.cep == null
            ? null
            : this.paciente.endereco.cep;
        this.overlay = false;
      })
      .catch((error) => {
        this.overlay = false;
        this.$swal.getCancelButton().setAttribute("enabled", "");
        this.$swal.showValidationMessage(`Erro: ${error}`);
        console.error(error.message);
      });
  },
};
</script>
